"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _2 = require("..");

var _useStaticInfo = require("../hooks/useStaticInfo");

var _useRoutePath = require("../hooks/useRoutePath"); //

/**
 *
 * @param {string} path
 * @returns {React.ComponentType<{}> | false}
 */


function getTemplateForPath(path) {
  var is404 = path === '404';
  var Comp = _2.templatesByPath[path] || false;

  if (!Comp && _2.templateErrorByPath[path]) {
    is404 = true;
    Comp = _2.templatesByPath['404'] || false;
  }

  return {
    is404: is404,
    Comp: Comp
  };
}
/**
 *
 *
 * @param {string} path
 * @returns {React.ReactNode | false}
 */


function getComponentForPath(path) {
  var _getTemplateForPath = getTemplateForPath(path),
      Comp = _getTemplateForPath.Comp,
      is404 = _getTemplateForPath.is404;

  if (is404 || !Comp) {
    return false;
  }

  return _react["default"].createElement(Comp, {
    is404: is404
  });
}

var RoutesInner = function RoutesInner(_ref) {
  var routePath = _ref.routePath,
      renderFn = _ref.render; // Let the user specify a manual routePath.
  // This is useful for animations where multiple routes
  // might be rendered simultaneously

  var staticInfo = (0, _useStaticInfo.useStaticInfo)(); // eslint-disable-next-line

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      _ = _useState2[0],
      setCount = _useState2[1]; // If in production, make sure the staticInfo is ingested into the
  // cache


  (0, _react.useState)(function () {
    // useState's initializer will only fire once per component instance,
    // and it will fire during the first render (unlike an effect, which
    // only fires after the first render). Think of it like a constructor call.
    if (process.env.REACT_STATIC_ENV === 'production' && staticInfo) {
      var path = staticInfo.path,
          sharedData = staticInfo.sharedData,
          sharedHashesByProp = staticInfo.sharedHashesByProp,
          template = staticInfo.template; // Hydrate routeInfoByPath with the embedded routeInfo

      _2.routeInfoByPath[path] = staticInfo; // Hydrate sharedDataByHash with the embedded routeInfo

      Object.keys(sharedHashesByProp).forEach(function (propKey) {
        _2.sharedDataByHash[sharedHashesByProp[propKey]] = sharedData[propKey];
      }); // In SRR and production, synchronously register the template for the
      // initial path

      (0, _2.registerTemplateForPath)(path, template);
    }
  });
  (0, _react.useEffect)(function () {
    return (0, _2.onReloadTemplates)(function () {
      setCount(function (old) {
        return old + 1;
      });
    });
  }); // If SSR, force the routePath to be the statically exported one

  if (typeof document === 'undefined') {
    routePath = staticInfo.path;
  } else if (!routePath) {
    // If a routePath is still not defined in the browser,
    // use the window location as the defualt
    routePath = decodeURIComponent(window.location.href);
  }

  routePath = (0, _useRoutePath.useRoutePath)(routePath); // Try and get the template

  var _getTemplateForPath2 = getTemplateForPath(routePath),
      Comp = _getTemplateForPath2.Comp,
      is404 = _getTemplateForPath2.is404;

  if (!Comp) {
    if (is404) {
      throw new Error('Neither the page template or 404 template could be found. This means something is terribly wrong. Please, file an issue!');
    } // Suspend while we fetch the resource


    throw Promise.all([new Promise(function (resolve) {
      return setTimeout(resolve, 500);
    }), (0, _2.prefetch)(routePath, {
      priority: true
    })]);
  }

  return _react["default"].createElement(_useRoutePath.routePathContext.Provider, {
    value: routePath
  }, renderFn ? renderFn({
    routePath: routePath,
    getComponentForPath: getComponentForPath
  }) : _react["default"].createElement(Comp, {
    is404: is404
  }));
};

var Routes = function Routes(_ref2) {
  var originalProps = (0, _extends2["default"])({}, _ref2); // Once a routePath goes into the Routes component,
  // useRoutePath must ALWAYS return the routePath used
  // in its parent, so we pass it down as context
  // Get the Routes hook

  var CompWrapper = (0, _react.useMemo)(function () {
    return _2.plugins.Routes(function (props) {
      return _react["default"].createElement(RoutesInner, props);
    });
  }, [_2.plugins]); // Pass all props so that plugins can use it

  return _react["default"].createElement(CompWrapper, originalProps);
};

var _default = Routes;
exports["default"] = _default;