"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _2 = require("..");

var _useRoutePath = require("./useRoutePath");

var _utils = require("../utils");

var useRouteData = function useRouteData() {
  var routePath = (0, _useRoutePath.useRoutePath)(); // eslint-disable-next-line

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      _ = _useState2[0],
      setCount = _useState2[1];

  (0, _react.useEffect)(function () {
    return (0, _2.onReloadClientData)(function () {
      setCount(function (old) {
        return old + 1;
      });
    });
  });
  var routeError = _2.routeErrorByPath[routePath];
  var routeInfo = routeError ? _2.routeInfoByPath['404'] : _2.routeInfoByPath[routePath]; // If there was an error reported for this path, throw an error
  // unless there is data for the 404 page

  if (routeError && (!routeInfo || !routeInfo.data)) {
    throw new Error("React-Static: useRouteData() could not find any data for this route: ".concat(routePath, ". If this is a dynamic route, please remove any calls to useRouteData() from this route's components"));
  }

  var targetRouteInfoPath = routeInfo ? routeInfo.path : routePath; // If we need to load data, suspend while it's requested

  if (shouldLoadData(routeInfo)) {
    throw Promise.all([new Promise(function (resolve) {
      return setTimeout(resolve, process.env.REACT_STATIC_MIN_LOAD_TIME);
    }), (0, _2.prefetch)(targetRouteInfoPath, {
      priority: true
    })]);
  } // Otherwise, return all of the data


  return (0, _utils.getFullRouteData)(routeInfo);
};

var _default = useRouteData;
exports["default"] = _default;

function shouldLoadData(routeInfo) {
  if (!routeInfo || !routeInfo.data) {
    return true;
  }

  return shouldLoadSharedData(routeInfo);
}

function shouldLoadSharedData(routeInfo) {
  return hasPropHashes(routeInfo) && !routeInfo.sharedData;
}

function hasPropHashes(routeInfo) {
  return routeInfo.sharedHashesByProp && Object.keys(routeInfo.sharedHashesByProp).length > 0;
}